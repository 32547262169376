<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div class="font-weight-bold mb-1">{{$t('Labels.Summarized')}}</div>
          <b-list-group>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconMedic/>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Messages.MedicEmergency')}}
                </b-col>
                <b-col>
                  {{global.total_emergencia_medica || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconPolice/>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Messages.Police')}}
                </b-col>
                <b-col>
                  {{global.total_policia || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconFire/>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Messages.Firefighters')}}
                </b-col>
                <b-col>
                  {{global.total_bomberos || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconContact/>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Messages.Contact')}}
                </b-col>
                <b-col>
                  {{global.total_contacto || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconNoAlert/>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Messages.NoAlert')}}
                </b-col>
                <b-col>
                  {{global.total_sin_alerta || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-list-group>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconBatteryPercent/>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Labels.BatteryHigh')}}
                </b-col>
                <b-col>
                  {{global.total_battery_high || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconBatteryPercent :percent="50"/>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Labels.BatteryMedium')}}
                </b-col>
                <b-col>
                  {{global.total_battery_medium || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconBatteryPercent :percent="5"/>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Labels.BatteryLow')}}
                </b-col>
                <b-col>
                  {{global.total_battery_low || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-list-group>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconBase>
                      <iconClock/>
                    </iconBase>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Labels.LastHour')}}
                </b-col>
                <b-col>
                  {{global.total_updated_1h || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconBase>
                      <iconClock/>
                    </iconBase>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Labels.Last24Hours')}}
                </b-col>
                <b-col>
                  {{global.total_updated_24h || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col cols="1">
                  <div class="icon-sum">
                    <iconBase>
                      <iconClock/>
                    </iconBase>
                  </div>
                </b-col>
                <b-col >
                  Total {{$t('Labels.LastDay')}}
                </b-col>
                <b-col>
                  {{global.total_updated_1d || 0}}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import request from '@/libs/request/index'
import iconContact from '@core/spore-components/icons/iconContact'
import iconFire from '@core/spore-components/icons/iconFire'
import iconPolice from '@core/spore-components/icons/iconPolice'
import iconMedic from '@core/spore-components/icons/iconMedic'
import iconNoAlert from '@core/spore-components/icons/iconNoAlert'
import iconBatteryPercent from '@core/spore-components/icons/iconBatteryPercent'
import iconClock from '@core/spore-components/icons/iconClock'
import iconBase from '@core/spore-components/icons/iconBase'

import {
  BCard,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol
} from 'bootstrap-vue'

export default {
  components: {
    iconContact,
    iconFire,
    iconPolice,
    iconMedic,
    iconNoAlert,
    iconBatteryPercent,
    iconClock,
    iconBase,
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol
  },
  data () {
    return {
      global: {}
    }
  },
  methods: {
    async getResponse () {
      const params = {
        url: 'trackers_admin/stadistics',
        method: 'GET'
      }
      await request(params).then(response => {
        this.global = response.data.data[0] || {}
      }).catch(() => {
        this.global = {}
      })
    }
  },
  created () {
    this.getResponse()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Dashboard.scss";
</style>
